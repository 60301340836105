export default [
  {
    _name: 'CSidebarNav',
    _children: [
      {
        _name: 'CSidebarNavItem',
        name: 'Главная',
        to: '/dashboard',
        icon: 'cil-speedometer'
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Международные заказы',
        to: '/orders-international',
        icon: 'cil-flight-takeoff'

      },
      {
        _name: 'CSidebarNavItem',
        name: 'Местные заказы',
        to: '/orders-local',
        icon: 'cil-truck'
      },
      {
        _name: 'CSidebarNavTitle',
        _children: ['Международные']
      },
      {
        _name: 'CSidebarNavDropdown',
        name: 'Регионы с офисами',
        route: '/international/',
        icon: 'cil-location-pin',
        items: [
          {
            name: 'Страны',
            to: '/international/countries'
          },
          {
            name: 'Регионы',
            to: '/international/regions'
          },
          {
            name: 'Города',
            to: '/international/cities'
          },
          {
             name: 'Офисы',
             to: '/international/branches'
           },
        ]
      },
      {
        _name: 'CSidebarNavTitle',
        _children: ['Местные']
      },
      {
        _name: 'CSidebarNavDropdown',
        name: 'Регионы с офисами',
        route: '/local',
        icon: 'cil-location-pin',
        items: [
          {
            name: 'Области',
            to: '/local/regions'
          },
          {
            name: 'Районы',
            to: '/local/districts'
          },
          {
            name: 'Офисы',
            to: '/local/branches'
           },
        ]
      },
      {
        _name: 'CSidebarNavDropdown',
        name: 'Сотрудники',
        route: '/users',
        icon: 'cil-people',
        items: [
          {
            name: 'Все сотрудники',
            to: '/users'
          },
          {
            name: 'Админы',
            to: '/users/role/admin'
          },
          {
            name: 'Модераторы',
            to: '/users/role/moderator'
          },
          {
            name: 'Операторы',
            to: '/users/role/operation'
          },
          {
            name: 'Водители',
            to: '/users/role/driver'
          },
          {
            name: 'Бухгалтерия',
            to: '/'
          }
        ]
      },
      {
        _name: 'CSidebarNavDropdown',
        name: 'Клиенты',
        to: '/clients',
        icon: 'cil-people',
        items: [
          {
            name: 'Клиенты',
            to: '/clients'
          },
          {
            name: 'Адреса',
            to: '/clients/addresses'
          },
          {
            name: 'Договора',
            to: '/clients/contracts'
          },
          {
            name: 'Шаблоны договоров',
            to: '/clients/contracts/templates'
          },
        ]
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Поставщики',
        to: '/providers',
        icon: 'cil-people'
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Промокоды',
        to: '/promocodes',
        icon: 'cib-adguard'
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Отслеживание водителей',
        to: '/tracking-drivers',
        icon: 'cib-openstreetmap'
      },
      {
        _name: 'CSidebarNavTitle',
        _children: ['Детали заказа']
      },
      {
        _name: 'CSidebarNavDropdown',
        name: 'Детали',
        route: '/details',
        icon: 'cil-puzzle',
        items: [
          {
            name: 'Статусы',
            to: '/details/statuses'
          },
          {
            name: 'Тип груза',
            to: '/details/type-of-cargo'
          },
          {
            name: 'Тип услуги',
            to: '/details/type-of-service'
           },
        ]
      },

      {
        _name: 'CSidebarNavDropdown',
        name: 'Тарифы',
        route: '/tariffs',
        icon: 'cil-layers',
        items: [
          {
            name: 'Тарифы',
            to: '/tariffs'
          },
          {
            name: 'Правила оформления тарифа',
            to: '/tariffs/create'
          },
        ]
      },

      {
        _name: 'CSidebarNavTitle',
        _children: ['Рассылки']
      },
      {
        _name: 'CSidebarNavDropdown',
        name: 'Email Рассылка',
        route: '/mailing',
        icon: 'cil-send',
        items: [
          {
            name: 'Группы',
            to: '/mailing/email-groups'
          },
          {
            name: 'Абоненты',
            to: '/mailing/email-abonents'
          },
          {
            name: 'Рассылка',
            to: '/mailing/email-mailing'
           },
        ]
      },
      {
        _name: 'CSidebarNavItem',
        name: 'SMS Рассылка',
        to: '/tracking-drivers',
        icon: 'cil-send'
      },
    ]
  }
]
